

import {computed, defineComponent, onMounted, ref} from "vue";
import * as Yup from "yup";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {ErrorMessage, Field, Form} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Tagify from '@yaireo/tagify';
import {hideModal} from "@/core/helpers/dom";
import curList from "@/core/data/currency";

interface intFormVals {
    TransferID: string;
    Markets: string;
    Accounts: string;
    Locations: string;
    Price: string;
    CHDPrice: string;
    INFPrice: string;
    Cur: string;
}

export default defineComponent({

    name: "operation-transfer-price-add",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    props: {
        transferID : String,
        transferType : String,
    },
    setup(props) {

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
        const myAccounts = computed(() => {
            const tmp= store.getters.accountList;
            return tmp;
        });

        store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
        const myTransferLocations = computed(() => {
            const tmp= store.getters.transferLocationsList;
            return tmp;
        });

        const myTagsMarkets = computed(() => {
            if (myMarkets.value.data) {
                let tmpList = [];
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'market',
                        ID: myMarkets.value.data[i].ID,
                    };
                }
                setTagInputMarkets(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });

        const myTagsAccounts = computed(() => {
            if (myAccounts.value.data!== undefined) {
                let tmpList = [];
                for (let i = 0; i < myAccounts.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myAccounts.value.data[i].Title).trim(),
                        title: (myAccounts.value.data[i].Title).trim(),
                        type: 'Departments',
                        ID: myAccounts.value.data[i].ID,
                    };
                }
                setTagInputAccounts(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });

        const myTagsLocations = computed(() => {
            if (myTransferLocations.value.data!== undefined) {
                let tmpList = [];
                for (let i = 0; i < myTransferLocations.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myTransferLocations.value.data[i].Title).trim(),
                        title: (myTransferLocations.value.data[i].Title).trim(),
                        type: 'Locations',
                        ID: myTransferLocations.value.data[i].ID,
                    };
                }
                setTagInputLocations(tmpList)
                return tmpList;
            } else {
                return [];
            }
        });

        let tagify;
        let tagify2;
        let tagify3;

        const setTagInputMarkets = (wList) => {
            var input = document.querySelector('input[name=myTagsInputMarkets]');
            tagify = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        const setTagInputAccounts = (wList) => {
            var input = document.querySelector('input[name=myTagsInputAccounts]');
            tagify2 = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        const setTagInputLocations = (wList) => {
            var input = document.querySelector('input[name=myTagsInputLocations]');
            tagify3 = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        function onAccountChange(e) {
            let tmpObj = JSON.parse(e.target.value);
            formVals.value.Accounts = tmpObj;
        }

        function onMarketChange(e) {
            let tmpObj = JSON.parse(e.target.value);
            formVals.value.Markets = tmpObj;
        }

        function onLocationChange(e) {
            let tmpObj = JSON.parse(e.target.value);
            formVals.value.Locations = tmpObj;
        }

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const formVals = ref<intFormVals>({
            TransferID: "",
            Markets: "",
            Accounts: "",
            Locations: "",
            Cur: "",
            Price: "",
            CHDPrice: "",
            INFPrice: "",
        });

        const validationSchema = Yup.object().shape({
            Cur: Yup.string().required("Doldurulması zorunlu"),
            Price: Yup.string().required("Doldurulması zorunlu"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                TransferID: props.transferID,
                Markets: JSON.stringify(formVals.value.Markets),
                Accounts: JSON.stringify(formVals.value.Accounts),
                Locations: JSON.stringify(formVals.value.Locations),
                Cur: formVals.value.Cur,
                Price: formVals.value.Price,
                CHDPrice: "0",
                INFPrice: "0",
            }

            if (props.transferType==="PAX") {
                payload1.CHDPrice = formVals.value.CHDPrice;
                payload1.INFPrice = formVals.value.INFPrice;
            } else {
                delete payload1.CHDPrice;
                delete payload1.INFPrice;
            }

            //console.log("submit v1 payload1: "+JSON.stringify(payload1));

            store.dispatch(Actions.TRANSFER_PRICES_INSERT, payload1)
                .then(() => {
                    store.dispatch(Actions.TRANSFER_PRICES_LIST, {TransferID: props.transferID});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde ekleme işlemini tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {confirmButton: "btn fw-bold btn-light-primary",},
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                    Swal.fire({
                        text: store.getters.getUserErrors[0],
                        icon: "error",
                        buttonsStyling: false,
                        confirmButtonText: "Tekrar dene!",
                        customClass: {confirmButton: "btn fw-bold btn-light-danger",},
                    });
                    submitButtonRef.value.disabled = false;
                    submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                });
        };

        return {
            formVals, validationSchema, submit,
            submitButtonRef, modalRef,
            myMarkets, myAccounts, myTransferLocations,
            myTagsMarkets, myTagsAccounts, myTagsLocations,
            onMarketChange, onAccountChange, onLocationChange,
            curList,
        };
    },
});

