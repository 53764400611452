const curList = {
  "TYPES" : [
    {
      Value : 'TRY',
      Title : 'Türk Lirası',
      Shorthand : 'TRY',
      icon: 'las la-lira-sign'
    },
    {
      Value : 'USD',
      Title : 'Amerikan Doları',
      Shorthand: 'USD',
      icon: 'las la-dollar-sign'
    },
    {
      Value : 'EUR',
      Title : 'Euro',
      Shorthand: 'EUR',
      icon: 'las la-euro-sign'
    },
    {
      Value : 'STG',
      Title : 'İngiliz Sterlini',
      Shorthand: 'STG',
      icon: 'las la-pound-sign'
    }],
};
export default curList;
