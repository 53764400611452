

import {computed, defineComponent, ref, watch} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import {ErrorMessage, Field, Form} from "vee-validate";
import Tagify from '@yaireo/tagify';
import curList from "@/core/data/currency";
import * as Yup from "yup";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";

interface newItemData {
    TransferID: string;
    Markets: string;
    Accounts: string;
    Locations: string;
    Cur: string;
    Price: string;
    CHDPrice: string;
    INFPrice: string;
}
export default defineComponent({
    name: "operation-transfer-price-update",
    components: {
        Field, Form, ErrorMessage,
    },
    props: {
        itemID: String,
        transferType : String,
    },

    setup(props) {
        watch(
            () => props.itemID,
            () => {
                store.dispatch(Actions.TRANSFER_PRICES_DETAILS, {'ID': props.itemID});
            }
        );

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const myDetails = computed(() => {
            let dataComputed = store.getters.transferPricesDetails
            setData(dataComputed);
            return dataComputed;
        });

        store.dispatch(Actions.MARKETS_LIST_CACHE, {});
        const myMarkets = computed(() => {
            return store.getters.MarketsListCache;
        });

        store.dispatch(Actions.ACCOUNT_LIST_ALL, {});
        const myAccounts = computed(() => {
            return store.getters.accountList;
        });

        store.dispatch(Actions.TRANSFER_LOCATIONS_LIST, {});
        const myTransferLocations = computed(() => {
            const tmp= store.getters.transferLocationsList;
            return tmp;
        });

        const setData = (prmData) => {
            newItemData.value.TransferID = prmData.TransferID;
            newItemData.value.Markets = prmData.Markets;
            newItemData.value.Accounts = prmData.Accounts;
            newItemData.value.Locations = prmData.Locations;
            newItemData.value.Cur = prmData.Cur;
            newItemData.value.Price = prmData.Price;
            newItemData.value.CHDPrice = prmData.CHDPrice;
            newItemData.value.INFPrice = prmData.INFPrice;
        }

        let locTagAddStatus = false;
        const myTagsLocations = computed(() => {
            if (myTransferLocations.value.data && myDetails.value.ID) {
                let tmpList = [];let tmpSelList = []; let selData = JSON.parse(myDetails.value.Locations);
                for (let i = 0; i < myTransferLocations.value.data.length; i++) {
                    //console.log("myTransferLocations.value.data[i]: "+JSON.stringify(myTransferLocations.value.data[i]));
                    //{"ID":"19","Type":"LOCATION","Title":"ACAPULCO","s_comp":"CDG-MYCOMP01","is_active":"active","created_at":"2023-07-11 14:43:24","updated_at":"2023-07-11 14:43:24","deleted_at":null,"created_by":"40","updated_by":null,"deleted_by":null}
                    tmpList[i] = {
                        value: (myTransferLocations.value.data[i].Title).trim(),
                        title: (myTransferLocations.value.data[i].Title).trim(),
                        type: 'location',
                        ID: myTransferLocations.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myTransferLocations.value.data[i].ID) {
                            tmpSelList[is] = {
                                value: (myTransferLocations.value.data[i].Title).trim(),
                                title: (myTransferLocations.value.data[i].Title).trim(),
                                type: 'location',
                                ID: myTransferLocations.value.data[i].ID,
                            };
                        }
                    }
                }
                if(locTagAddStatus == false) {setTagInputLocations(tmpList); locTagAddStatus=true;}
                let tags = (document.getElementById('myTagsUpdateInputLocations') as HTMLInputElement);
                if(tags.value!='')tagifyLocation.removeAllTags();
                if(tmpSelList.length>0) {tagifyLocation.addTags(tmpSelList);}
                return tmpList;

            } else {
                return [];
            }
        });

        let marTagAddStatus = false;
        const myTagsMarkets = computed(() => {
            if (myMarkets.value.data && myDetails.value.ID) {
                let tmpList = [];let tmpSelList = []; let selData = JSON.parse(myDetails.value.Markets);
                for (let i = 0; i < myMarkets.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                        title: (myMarkets.value.data[i].ShortTitle).trim(),
                        cur: myMarkets.value.data[i].Cur,
                        type: 'market',
                        ID: myMarkets.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myMarkets.value.data[i].ID) {
                            tmpSelList[is] = {
                                value: (myMarkets.value.data[i].Title).trim() + "(" + myMarkets.value.data[i].Cur + ")",
                                title: (myMarkets.value.data[i].ShortTitle).trim(),
                                cur: myMarkets.value.data[i].Cur,
                                type: 'market',
                                ID: myMarkets.value.data[i].ID,
                            };
                        }
                    }
                }
                if(marTagAddStatus == false) {setTagInputMarkets(tmpList); marTagAddStatus=true;}
                let tags = (document.getElementById('myTagsUpdateInputMarkets') as HTMLInputElement);
                if(tags.value!='')tagifyMarket.removeAllTags();
                if(tmpSelList.length>0) {tagifyMarket.addTags(tmpSelList);}
                return tmpList;

            } else {
                return [];
            }
        });

        let accTagAddStatus = false;
        let tmpSelList = [];
        const myTagsAccounts = computed(() => {
            if (myAccounts.value.data !== undefined && myDetails.value.ID) {
                let tmpList = [];
                let selData = JSON.parse(myDetails.value.Accounts);
                for (let i = 0; i < myAccounts.value.data.length; i++) {
                    tmpList[i] = {
                        value: (myAccounts.value.data[i].Title).trim(),
                        title: (myAccounts.value.data[i].Title).trim(),
                        type: 'Accounts',
                        ID: myAccounts.value.data[i].ID,
                    };
                    for (let is = 0; is < selData.length; is++) {
                        if (selData[is].ID == myAccounts.value.data[i].ID) {
                            // eslint-disable-next-line vue/no-side-effects-in-computed-properties
                            tmpSelList[is] = {
                                value: (myAccounts.value.data[i].Title).trim(),
                                title: (myAccounts.value.data[i].Title).trim(),
                                type: 'Accounts',
                                ID: myAccounts.value.data[i].ID,
                            };
                        }

                    }
                }
                if(accTagAddStatus==false){setTagInputAccounts(tmpList);accTagAddStatus=true;}
                let tags = (document.getElementById('myTagsUpdateInputMarkets') as HTMLInputElement);
                if(tags.value!='')tagifyAccount.removeAllTags();
                if(tmpSelList.length>0) {tagifyAccount.addTags(tmpSelList);}
                return tmpList;
            } else {
                return [];
            }
        });

        let tagifyMarket;
        let tagifyAccount;
        let tagifyLocation;

        const setTagInputMarkets = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInputMarkets]');
            tagifyMarket = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        const setTagInputAccounts = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInputAccounts]');
            tagifyAccount = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        const setTagInputLocations = (wList) => {
            var input = document.querySelector('input[name=myTagsUpdateInputLocations]');
            tagifyLocation = new Tagify(input, {
                tagTextProp: 'value', // very important since a custom template is used with this property as text
                enforceWhitelist: true,
                duplicates: false,
                skipInvalid: true, // do not remporarily add invalid tags
                whitelist: wList,
                dropdown: {
                    searchKeys: ["value"],
                    maxItems: 5,
                    closeOnSelect: false,
                    position: "text",
                    enabled: 0, // always opens dropdown when input gets focus
                }
            });
        }

        const newItemData = ref<newItemData>({
            TransferID: "",
            Markets: "",
            Accounts: "",
            Locations: "",
            Cur: "",
            Price: "",
            CHDPrice: "",
            INFPrice: "",
        });

        function onLocationChange(e) {
            if(e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.Locations = tmpObj;
            }else{
                let tmpObj = "";
                newItemData.value.Locations = tmpObj;
            }
        }

        function onAccountChange(e) {
            if(e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.Accounts = tmpObj;
            }else{
                let tmpObj = "";
                newItemData.value.Accounts = tmpObj;
            }
        }

        function onMarketChange(e) {
            if(e.target.value !== "") {
                let tmpObj = JSON.parse(e.target.value);
                newItemData.value.Markets = tmpObj;
            }else{
                let tmpObj = "";
                newItemData.value.Markets = tmpObj;
            }
        }

        const validationSchema = Yup.object().shape({
            Cur: Yup.string().required("Doldurulması zorunlu"),
            Price: Yup.string().required("Doldurulması zorunlu"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                ID: props.itemID,
                TransferID: myDetails.value.TransferID,
                Markets: (typeof newItemData.value.Markets !="string") ? (JSON.stringify(newItemData.value.Markets)).replace(/\\/g, '') : '""',
                Accounts: (typeof newItemData.value.Accounts !="string") ? (JSON.stringify(newItemData.value.Accounts)).replace(/\\/g, '') : '""',
                Locations: (typeof newItemData.value.Locations !="string") ? (JSON.stringify(newItemData.value.Locations)).replace(/\\/g, '') : '""',
                Cur: newItemData.value.Cur,
                Price: newItemData.value.Price,
                CHDPrice: "0",
                INFPrice: "0",
            };

            if (props.transferType==="PAX") {
                payload1.CHDPrice = newItemData.value.CHDPrice;
                payload1.INFPrice = newItemData.value.INFPrice;
            } else {
                delete payload1.CHDPrice;
                delete payload1.INFPrice;
            }

            console.log("submit v1 "+JSON.stringify(payload1));

            store.dispatch(Actions.TRANSFER_PRICES_EDIT, payload1)
                .then(() => {
                    store.dispatch(Actions.TRANSFER_PRICES_LIST, {TransferID: myDetails.value.TransferID});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getUserErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        };

        return {
            newItemData, modalRef, submitButtonRef, validationSchema, submit,
            myDetails, myMarkets, myAccounts, myTransferLocations,
            onAccountChange, onMarketChange, onLocationChange,
            myTagsMarkets, myTagsAccounts, myTagsLocations,
            curList,
        }
    }
});

