
import {computed, defineComponent, ref} from "vue";
import {ErrorMessage, Field, Form} from "vee-validate";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import * as Yup from "yup";
import {hideModal} from "@/core/helpers/dom";
import Swal from "sweetalert2/dist/sweetalert2.js";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "operation-transfer-update",
    components: {
        Field,
        Form,
        ErrorMessage,
    },
    props: {
        itemID: String,
    },
    setup(props, ctx) {
        store.dispatch(Actions.TRANSFER_DETAILS, {'ID': props.itemID});

        let isEmit = true;

        const myDetails = computed(() => {
            let dataComputed =store.getters.transfersDetails;
            detailTaken(dataComputed);
            return dataComputed;
        });

        const detailTaken = (prmDetail) => {
            if (prmDetail.ID!==undefined) {
                if (isEmit) {
                    //isEmit = false;
                    ctx.emit('detailChanged', prmDetail);
                }
            }
        }

        const submitButtonRef = ref<null | HTMLButtonElement>(null);
        const modalRef = ref<null | HTMLElement>(null);

        const validationSchema = Yup.object().shape({
            Title: Yup.string().required("Transfer Adı girilmemiş"),
            Type: Yup.string().required("Transfer Tipi seçilmemiş"),
            is_sale: Yup.string().required("Durum seçilmemiş"),
        });

        const submit = () => {
            if (!submitButtonRef.value) {
                return;
            }

            const payload1 = {
                ID: props.itemID,
                Title: myDetails.value.Title,
                Type: myDetails.value.Type,
                is_sale: myDetails.value.is_sale,
            }

            store.dispatch(Actions.TRANSFER_EDIT, payload1)
                .then(() => {
                    isEmit = true;
                    store.dispatch(Actions.TRANSFER_DETAILS, {'ID': props.itemID});
                    Swal.fire({
                        text: "Tebrikler. Başarılı bir şekilde işlemi tamamladınız.",
                        icon: "success",
                        buttonsStyling: false,
                        confirmButtonText: "Geri Dön!",
                        customClass: {
                            confirmButton: "btn fw-bold btn-light-primary",
                        },
                    }).then(function () {
                        submitButtonRef.value.disabled = false;
                        submitButtonRef.value.setAttribute("data-kt-indicator", "off");
                        hideModal(modalRef.value);
                    });
                }).catch(() => {
                Swal.fire({
                    text: store.getters.getUserErrors[0],
                    icon: "error",
                    buttonsStyling: false,
                    confirmButtonText: "Tekrar dene!",
                    customClass: {
                        confirmButton: "btn fw-bold btn-light-danger",
                    },
                });
                submitButtonRef.value.disabled = false;
                submitButtonRef.value.setAttribute("data-kt-indicator", "off");
            });

        };

        return {
            myDetails, validationSchema,
            submitButtonRef, modalRef, submit,
            libTools,
        };
    },
});
