


import {defineComponent, ref} from "vue";
import {useRoute} from "vue-router";

import PriceList from "@/components/operations/transfers/transfers/transferprices/List.vue";
import PriceAdd from "@/components/operations/transfers/transfers/transferprices/AddNew.vue";
import Update from "@/components/operations/transfers/transfers/Update.vue";

export default defineComponent({
    name: "transfer-detail",
    components: {
        PriceList, Update, PriceAdd,
    },
    setup() {
        const route = useRoute();
        const paramID = route.params.id;

        var theDetailType = ref("");

        const detailChanged= (prmDetail)=> {
            console.log("detailChanged Detail.vue "+JSON.stringify(prmDetail))
            theDetailType.value = prmDetail.Type;
        };

        return {
            paramID, detailChanged, theDetailType,
        };
    },
});

