
import {computed, defineComponent, ref} from "vue";
import store from "@/store";
import {Actions} from "@/store/enums/StoreEnums";
import Swal from "sweetalert2/dist/sweetalert2.js";
import Update from "@/components/operations/transfers/transfers/transferprices/Update.vue";
import {libTools} from "@/core/appcore/libs/Lib_Tools";

export default defineComponent({
    name: "transfer-price-list",
    components: {
        Update,
    },
    props: {
        transferID: String,
        transferType: String
    },
    setup(props) {

        store.dispatch(Actions.TRANSFER_PRICES_LIST, {TransferID: props.transferID});
        const myTransferPrices = computed(() => {
            return store.getters.transferPricesList;
        });

        const deletePrice = (ID) => {

            Swal.fire({
                title: 'Emin misiniz?',
                text: "Kaydi silmek işlemi geri alınamaz.",
                icon: "warning",
                showCancelButton: true,
                confirmButtonText: 'Kaydı Sil',
                cancelButtonText: `Vazgeç`,
                confirmButtonColor: '#9c1d1d',
                cancelButtonColor: '#3085d6',
            }).then(function (result) {
                if (result.isConfirmed) {
                    store.dispatch(Actions.TRANSFER_PRICES_DELETE, {ID: ID,})
                        .then(() => {
                            store.dispatch(Actions.TRANSFER_PRICES_LIST, {TransferID: props.transferID});
                            Swal.fire(
                                'Silindi!',
                                'Kayit silme işlemi tamamlandı.',
                                'success'
                            )
                        }).catch(() => {
                        Swal.fire({
                            text: store.getters.getTransferCarsErrors[0],
                            icon: "error",
                            buttonsStyling: false,
                            confirmButtonText: "Tekrar dene!",
                            customClass: {
                                confirmButton: "btn fw-bold btn-light-danger",
                            },
                        });
                    });
                }
            });
        };

        const SelItemID = ref<number>(0);
        function setItems(ID) {
            SelItemID.value=ID;
        }

        function getPriceHeaderByTransferType() {
            if (props.transferType==="PAX") {
                return "Yetişkin / Cocuk / Bebek";
            }
            return libTools.method.getTransferTypeText(props.transferType);
        }

        function getPriceValuesByTransferType(prmItem) {
            if (props.transferType==="PAX") {
                return getZeroIfNull(prmItem.Price)+ " / "+ getZeroIfNull(prmItem.CHDPrice) + " / "+ getZeroIfNull(prmItem.INFPrice);
            }
            return prmItem.Price;
        }

        function getZeroIfNull(prmValue) {
            if (prmValue===undefined || prmValue===null) {
                return 0;
            }
            return prmValue;
        }

        return {
            libTools,
            myTransferPrices,
            deletePrice,
            setItems,
            SelItemID,
            getPriceHeaderByTransferType,
            getPriceValuesByTransferType,
        };
    },
});
